import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { firebaseDB } from "../../../components/MaterialOnFire/firebase-config";
import {Modify} from "notistack";

export interface TTNMessage {
  time: Date;
  type: "uplink" | "downlink" | "queue";
  payload: string;
  instructionName?: string;
  status?: "enqueued" | "successful" | "failed";
  enqueuedBy? : string
}

export const readTTNUplinkMessage = async (id: string) => {
  const loraNodes = query(
    collection(firebaseDB, "/ttnMessage/ttnMessage/uplink"),
    where("end_device_ids.device_id", "==", id),
    orderBy("received_at", "desc"),
    limit(10)
  );
  const uplinkMessage: TTNMessage[] = [];
  try {
    const snapshotUplinkMessage = await getDocs(loraNodes);
    snapshotUplinkMessage.forEach((doc) => {
      let data = doc.data();
      uplinkMessage.push({
        time: new Date(data?.received_at) || "",
        type: "uplink",
        payload: JSON.stringify(data?.uplink_message?.decoded_payload) || "",
      });
    });
  } catch (e) {
    console.log(e);
  }

  return uplinkMessage;
};

export const readTTNDownlinkkMessage = async (id: string) => {
  const loraNodes = query(
    collection(firebaseDB, "/ttnMessage/ttnMessage/downlinkSent"),
    where("end_device_ids.device_id", "==", id),
    orderBy("received_at", "desc"),
    limit(10)
  );
  const uplinkMessage: TTNMessage[] = [];
  try {
    const snapshotUplinkMessage = await getDocs(loraNodes);
    snapshotUplinkMessage.forEach((doc) => {
      let data = doc.data();
      uplinkMessage.push({
        time: new Date(data?.received_at) || "",
        type: "downlink",
        payload: data?.downlink_sent?.decoded_payload?.bytes?.toString() || "",
      });
    });
  } catch (e) {
    console.log(e);
  }

  return uplinkMessage;
};

export const readTTNDownlinkQueueMessages = async (id: string) => {
  const downlikQueue = query(
      collection(firebaseDB, "/downlinkQueue"),
      where("payload.deviceId", "==", id),
      orderBy("enqueuedAt", "desc"),
      limit(10)
  );

  console.log(downlikQueue);
  const downlinkQueueMessages: TTNMessage[] = [];
  try {
    const snapshotDownlinkQueue = await getDocs(downlikQueue);
    snapshotDownlinkQueue.forEach((queuedMessage) => {
      let queuedMessageData = queuedMessage.data() as any;
      downlinkQueueMessages.push({
        time: new Date(queuedMessageData.enqueuedAt) || "",
        type: "queue",
        status: queuedMessageData.status,
        enqueuedBy: queuedMessageData.enqueuedBy,
        payload: queuedMessageData.payload.instruction.join(" "),
        instructionName : queuedMessageData.payload.instructionName
      });
    });
    console.log(downlinkQueueMessages);
  } catch (e) {
    console.log(e);
  }

  return downlinkQueueMessages;
};
